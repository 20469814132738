<template>
  <v-container>
    <ValidationObserver ref="observer" v-slot="{ invalid, valid }">
      <v-card style="margin-top: 12px">
        <v-card-title style="font-weight: bold">Change Password</v-card-title>
        <v-card-text>
          <validation-provider
            v-slot="{ errors }"
            :rules="{ required: true, regex: /^.{5,}$/ }"
            name="new password"
            vid="confirm"
          >
            <v-text-field
              v-model="form.newPw"
              :error-messages="errors && errors.length ? 'Must be 5 characters or more' : ''"
              label="new password"
              outlined
              type="password"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="confirm new password"
            rules="required|confirmed:confirm"
          >
            <v-text-field
              v-model="form.confirmNewPw"
              :error-messages="errors"
              label="confirm new password"
              outlined
              type="password"
            />
          </validation-provider>
        </v-card-text>
      </v-card>
      <v-btn
        @click.stop="resetUserPassword"
        style="margin-top: 12px"
        class="onPrimary--text"
        color="green buttonClicked"
        :disabled="!valid"
      >
        Change Password
      </v-btn>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { resetPassword } from '@/api/auth/membership';
import api from '@/api';

export default {
  name: 'changePassword',
  props: {
    id: {},
  },
  data() {
    return {
      form: {
        oldPw: null,
        newPw: null,
        confirmNewPw: null,
      },
    };
  },
  methods: {
    async resetUserPassword() {
      try {
        await resetPassword(api, this.id, this.form.newPw);
        alert('Change password success');
        this.form = {
          oldPw: null,
          newPw: null,
          confirmNewPw: null,
        };
        await this.$refs.observer?.reset();
        await this.$router.go(-1);
      } catch (error) {
        alert('Change password fail');
      }
    },
  },
};
</script>

<style scoped></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var valid = ref.valid;
return [_c('v-card',{staticStyle:{"margin-top":"12px"}},[_c('v-card-title',{staticStyle:{"font-weight":"bold"}},[_vm._v("Change User Name")]),_c('v-card-text',[_c('validation-provider',{attrs:{"rules":{ required: true, regex: /^.{3,}$/ },"name":"new uesr name","vid":"confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors && errors.length ? 'Must be 3 characters or more' : '',"label":"new user name","outlined":"","type":"string"},model:{value:(_vm.form.newUserName),callback:function ($$v) {_vm.$set(_vm.form, "newUserName", $$v)},expression:"form.newUserName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"confirm new user name","rules":"required|confirmed:confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"confirm new user name","outlined":"","type":"string"},model:{value:(_vm.form.confirmeNewUserName),callback:function ($$v) {_vm.$set(_vm.form, "confirmeNewUserName", $$v)},expression:"form.confirmeNewUserName"}})]}}],null,true)})],1)],1),_c('v-btn',{staticClass:"buttonClicked",staticStyle:{"margin-top":"12px"},attrs:{"disabled":!valid},on:{"click":function($event){$event.stopPropagation();return _vm.resetUserName.apply(null, arguments)}}},[_vm._v(" Change User Name ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var valid = ref.valid;
return [_c('v-card',{staticStyle:{"margin-top":"12px"}},[_c('v-card-title',{staticStyle:{"font-weight":"bold"}},[_vm._v("Change Password")]),_c('v-card-text',[_c('validation-provider',{attrs:{"rules":{ required: true, regex: /^.{5,}$/ },"name":"new password","vid":"confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors && errors.length ? 'Must be 5 characters or more' : '',"label":"new password","outlined":"","type":"password"},model:{value:(_vm.form.newPw),callback:function ($$v) {_vm.$set(_vm.form, "newPw", $$v)},expression:"form.newPw"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"confirm new password","rules":"required|confirmed:confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"confirm new password","outlined":"","type":"password"},model:{value:(_vm.form.confirmNewPw),callback:function ($$v) {_vm.$set(_vm.form, "confirmNewPw", $$v)},expression:"form.confirmNewPw"}})]}}],null,true)})],1)],1),_c('v-btn',{staticClass:"onPrimary--text",staticStyle:{"margin-top":"12px"},attrs:{"color":"green buttonClicked","disabled":!valid},on:{"click":function($event){$event.stopPropagation();return _vm.resetUserPassword.apply(null, arguments)}}},[_vm._v(" Change Password ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container>
    <ValidationObserver ref="observer" v-slot="{ invalid, valid }">
      <v-card style="margin-top: 12px">
        <v-card-title style="font-weight: bold">Change User Name</v-card-title>
        <v-card-text>
          <validation-provider
            v-slot="{ errors }"
            :rules="{ required: true, regex: /^.{3,}$/ }"
            name="new uesr name"
            vid="confirm"
          >
            <v-text-field
              v-model="form.newUserName"
              :error-messages="errors && errors.length ? 'Must be 3 characters or more' : ''"
              label="new user name"
              outlined
              type="string"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="confirm new user name"
            rules="required|confirmed:confirm"
          >
            <v-text-field
              v-model="form.confirmeNewUserName"
              :error-messages="errors"
              label="confirm new user name"
              outlined
              type="string"
            />
          </validation-provider>
        </v-card-text>
      </v-card>
      <v-btn
        @click.stop="resetUserName"
        style="margin-top: 12px"
        class="buttonClicked"
        :disabled="!valid"
      >
        Change User Name
      </v-btn>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { patchUser } from '@/api/auth/membership';
import api from '@/api';

export default {
  name: 'changeUserName',
  props: {
    id: {},
  },
  data() {
    return {
      form: {
        oldUserName: null,
        newUserName: null,
        confirmeNewUserName: null,
      },
    };
  },
  methods: {
    async resetUserName() {
      try {
        let payload = {
          username: this.form.newUserName.trim(),
        };
        await patchUser(api, this.id, payload);
        alert('Change user name success');
        this.form = {
          oldUserName: null,
          newUserName: null,
          confirmeNewUserName: null,
        };
        await this.$refs.observer?.reset();
        await this.$router.go(-1);
      } catch (error) {
        alert('Change user name fail');
      }
    },
  },
};
</script>

<style scoped></style>
